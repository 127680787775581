import React,{useEffect, useState} from 'react';
// import StepsPrograssBar from 'react-line-progress-bar-steps';

const Scan = () => {
  const [bsscore,setBsScore]=useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('This will run every second!');

        getVal()
    }, 2000);
    return () => clearInterval(interval);
      }, []);

      const getVal=()=>{
        var bscore= document.getElementById("diag_score").value


        if(bscore!=bsscore){
         setBsScore(bscore)
        }
     }


    return (<>
    {/* <div className='text-2xl font-bold text-center py-3 border-4'>Docsun Real Time Detection</div> */}

      <input id="diag_score" />
    <div className='flex md:flex-row flex-col w-full'>
      <div className='flex bg-black'>
        {/* Video */}

        {/* <video hidden id="webcam" width="640" height="480" autoPlay></video>
        <canvas id="canvas" width="640" height="480"></canvas> */}
        {/* <video hidden id="webcam"  width="640" className='w-full' height="480" autoPlay></video>
        <canvas id="canvas"  width="640" className='w-full' height="480"></canvas> */}
        <video hidden id="webcam" width="640" height="480" autoPlay></video>
      <canvas id="canvas" width="640" height="480"></canvas>
      </div>
      <div className='flex flex-col flex-auto bg-black'>

        {/* Start of one row */}
        <div className='flex flex-row border-b'>
            <div className='w-2/3 border-r flex flex-row'>
              {/* Chart */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              {/* <img hidden id="hr_img" src="./vitalbox.png" alt="" /> <canvas id="hr_canvas" width="320" height="50"></canvas> */}
              <img hidden id="ir_img" src="./vitalbox.png" alt="" />
              <canvas class="ir_canvas" width="320" height="130"></canvas>
              <canvas class="ir_canvas" width="320" height="130"></canvas>
            </div>
            <div className='w-1/3'>
              {/* value */}
              <div className=' text-orange-500 flex flex-col justify-between '>

                  <div className='text-2xl font-black'>
                    Blood Sugar Level
                  </div>
                  <div className='flex flex-row'>
                    <div className='w-1/2'>
                    <input type="text" id="bs_rate" className='pt-3 bg-black w-20 text-4xl font-black' />

                    </div>
                    <div className='w-1/2 extra-width'>

                    <span class="score-label"></span>

                      <ul id="signal-strength">
                          <li class="signal-10" style={ bsscore >= 0 ? { paddingBottom:'10px', background:'green' } : {}}><div></div></li>
                          <li class="signal-20" style={ bsscore > 10 ? { paddingBottom:'20px', background:'green' } : {}}><div></div></li>
                          <li class="signal-30" style={ bsscore > 20 ? { paddingBottom:'30px', background:'green' } : {}}><div></div></li>
                          <li class="signal-40" style={ bsscore > 30 ? { paddingBottom:'40px', background:'#90ee90' } : {}}><div></div></li>
                          <li class="signal-50" style={ bsscore > 40 ? { paddingBottom:'50px', background:'#90ee90' } : {}}><div></div></li>
                          <li class="signal-60" style={ bsscore > 50 ? { paddingBottom:'60px', background:'yellow' } : {}}><div></div></li>
                          <li class="signal-70" style={ bsscore > 60 ? { paddingBottom:'70px', background:'yellow' } : {}}><div></div></li>
                          <li class="signal-80" style={ bsscore > 70 ? { paddingBottom:'80px', background:'yellow' } : {}}><div></div></li>
                          <li class="signal-90" style={ bsscore > 80 ? { paddingBottom:'90px', background:'red' } : {}}><div></div></li>
                          <li class="signal-100" style={ bsscore > 90 ? { paddingBottom:'100px', background:'red' } : {}}><div></div></li>
                      </ul>

                    </div>

                  </div>
                  <div className=''>
                    Range of Detection (63 mg/dl to 650 mg/dl)<br/>
                    Unit of detection mg/dl

                  </div>
                {/* <div className='flex flex-col mt-1 w-2/3'>

                  <div className= " text-2xl font-black">
                  Blood Sugar Level
                  </div>

                  <div>
                  mg/dL

                  </div>

                </div> */}
                <div className='text-4xl font-black w-1/3  '>

                  {/* <input type="text" id="bs_rate" className='pt-3 bg-black w-16 text-4xl font-black' /> */}
                </div>
                </div>
            </div>


        </div>  {/* End of one row */}
        <div className=' flex flex-col text-white'>

          <div>
          <h4><b>Instructions to Follow For Accurate Scanning :</b></h4>

        <ol className='list-disc ml-4'>
          <li>Please load the page and enable the internal or external camera to scan</li>
          <li>Position the camera accordingly in front of you and perform the scan in a well-lit environment</li>
          <li>Do not do the scan under much backlight</li>
          <li>Adjust your face position to the camera until the green frame is steadily shown and please stay still</li>
          <li>Remove facial accessories such as masks, eyeglasses, and contact lenses for better accurate detection</li>
          <li>The scan lasts untill the health status shows up on the screen. You can click "Stop Scanning" to see your stable results</li>
        </ol>

          </div>


        </div>








      </div>


    </div>


    <div className='flex flex-col my-4'>
          {/* Video Stream */}
          <div className='my-3 flex md:flex-row flex-col'>
            <div className='space-x-4'>



            <button hidden id="scan" className='btn'>Start Idle Scanning</button>
                    <button id="real_scan" className='btn btn-info'>Start Vital Scanning</button>
                    <button id="stop"  className='btn btn-success'>Stop Vital Scanning</button>

            </div>
            <div className='text-2xl'>
            <h4> Diagnosis :  <input type="text" id="diagnosis" /><input type="text" hidden id="diag_value" /></h4>
            </div>

            </div>
          <div className=''>
              {/* <video hidden id="webcam" width="640" height="480" autoPlay></video>
              <canvas id="canvas" width="640" height="480"></canvas> */}

            </div>

        </div>


  <div className='hidden'>
  <img hidden id="ir_img" src="./vitalbox.png" alt="" /> <canvas class="ir_canvas" width="320" height="130"></canvas><canvas class="ir_canvas" width="320" height="130"></canvas>
  </div>






    </> );
}

export default Scan;
